import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        websiteBackground: file(relativePath: { eq: "login-background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <BackgroundImage
        className={props.classToApply}
        fluid={data.websiteBackground.childImageSharp.fluid}
      />
    )}
  />
)
